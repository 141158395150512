.donation-option-card {
    margin-bottom: .5rem;
    padding: .5rem;
}
.donation-option-card:last-child {
    margin-bottom: 0; /* Do not add any margin bottom to the last donation option card */
}

.project-image {
  margin-bottom: .5rem;
  width: 100%;
}
