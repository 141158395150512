.donation-option-card {
  color: #fff;
  position: relative;
}

.donation-option-card * {
  color: #fff;
}

.donation-option-company-logo {
  max-width: 80px;
  padding-right: .75rem;
  padding-top: .75rem;
  position: absolute;
  right: 0;
  top: 0;
}

.donation-option-card .card-text {
  margin-right: 50px;
}
