.faq-content li {
    white-space: pre-line;
}

.faq-toggle {
    display: flex;
    justify-content: flex-end;
}

.faq-toggle .btn {
    color: #4d4b29;
}

.faq-toggle .btn:hover {
    color: #4d4b29;
}

.faq-toggle .btn:focus {
    box-shadow: 0 0 0 .2rem #c6c2a3;
}