.data-legend-accordion {
    color: #FFF;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5), 2px 2px 2px rgba(0,0,0,0);
    position: fixed;
    font-weight: bold;
    top: 80px;
    left: 1.5rem;
    z-index: 1;
    width: 200px;
}

.data-legend-item {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
}

.data-legend-item > div {
    margin-right: 9px;
}

.data-legend-color {
    border-radius: 2px;
    width: 15px;
    height: 15px;
}

.data-legend-value {
    font-size: 11px;
}

.data-legend-caret-wrapper {
    margin-left: 1rem;
}

.data-legend-caret {
    transition: transform .2s ease-in;
}

.data-legend-caret.active {
    transform: rotate(90deg);
}

.data-legend-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
}

@media only screen and (max-width: 767px) {
    .data-legend-accordion {
        left: 10px;
        top: 40px;
        width: 130px;
    }
}
