.menu-mobile-wrapper {
    background-color: #fff;
    left: 0;
    height: 50vh;
    overflow-y: auto;
    padding: 1rem;
    position: fixed;
    top: 50vh;
    transform: translateY(50vh);
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    visibility: hidden;
    width: 100vw;
    z-index: 1500;
}

.menu-mobile-wrapper .list-group-item {
    border: 0;
    padding-left: 0;
    padding-right: .5rem;
}
.menu-mobile-wrapper .list-group-item + .list-group-item {
    border-top: 1px solid rgba(0, 0, 0, .125);
}

.menu-mobile-wrapper .list-group-item-icon {
    align-self: center;
    height: 2rem;
    margin-right: 1rem;
    width: 2rem;
}

.menu-mobile-wrapper .list-group-item-checkbox {
    align-self: center;
    color: #c6c2a3;
}
.menu-mobile-wrapper .list-group-item-checkbox.active {
    color: #a7e144;
}

.menu-mobile-wrapper .list-group-item-checkbox * {
    font-size: 1.5rem;
}

.menu-mobile-wrapper.active {
    visibility: visible;
    transform: translateY(0);
}

.menu-mobile > .list-group-item {
    border-radius: 0 !important;
    border-top-width: 1px !important;
    margin-bottom: .5rem !important;
}
