.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    font-size: 18px;
    position: sticky;
    top: 0px;
    z-index: 110;
    background: #fff;
    border-bottom: 1px solid rgb(220, 220, 220)
}

.sidebar-header > * {
    flex-grow: 0;
}

.sidebar-header-icon {
    margin: 0 .5rem;
    cursor: pointer;
}

.sidebar-header-center {
    flex-grow: 1;
}

.sidebar-header-center > * {
    margin: 0;
}

.sidebar-cover {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.sidebar-cover-image {
    border: 1px solid #979797;
    width: 100%;
    height: 220px;
    background-position: center top;
    background-size: cover;
    /* display: flex;
    align-items: flex-start;
    justify-content: space-between; */
}

.sidebar-cover-after {
    width: 100%;
    padding: .3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-sidebar-content {
    /* [OCW-52] Partially fixes the problem with the horizontal slide animation.
    It is caused by `position: sticky;` used in sidebar-header. */
    overflow-x: hidden;

    padding: 1.5rem;
}

.sidebar-sidebar-content h1,
.sidebar-sidebar-content h2,
.sidebar-sidebar-content h3,
.sidebar-sidebar-content h4,
.sidebar-sidebar-content h5,
.sidebar-sidebar-content h6,
.sidebar-sidebar-content p {
    white-space: pre-wrap;
}

.sidebar-sidebar-content h3 {
    margin-top: 2rem;
}

.sidebar-sidebar-content .checkout-form {
    margin-bottom: 2rem;
}
