:root {
  --body-font-size: 16px;
  --color-primary: #a7e144;
  --color-medication: #90e1ca;
  --color-money: #966cb7;
  --color-education: #c89c3a;
  --color-food: #a6cb42;
  --color-water: #60aec7;
  --color-reforestation: #498a44;
  --color-hzpc: #f60103;
  --swipeable-button-height: 3rem;
  --sidebar-width: calc(500px + 3rem);
  --spacing: 12px;
}

body {
  background: #000;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100vw;
}
body * {
  font-family: 'Open Sans', sans-serif !important;
  /* color: #4D4B29; */
  /* font-size: var(--body-font-size); */
  /* -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision; */
}

h1, h2, h3, h4, h5, h6 {
  color: #4D4B29;
  font-weight: bold;
}

a, p {
  color: #4D4B29;
  font-size: 15px;
}

a {
  font-weight: bold;
}

/* Make react-globe.gl Globe canvas follow size changes */
#root,
.main-content,
.main-content .globe-container,
.main-content .globe-container > div:not(.infobox) {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* A predefined id of react-jitsi. Use inline style to modify */
#react-jitsi-container {
}

/* A predefined id of react-jitsi. Use inline style to modify */
#react-jitsi-frame {
}

/* Overwrite some Bootsrap */
.alert-primary {
  background-color: #0195ff;
  border-color: #0195ff;
  color: #fff;
  padding: 2rem;
}
.alert-primary * {
  color: #fff;
}
.alert-primary p {
  margin: 0;
}

/* Overwrite some Bootsrap */
.bg-primary {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}
.bg-warning {
  background-color: #ffaa00 !important;
  border-color: #ffaa00 !important;
}

/* Overwrite some Bootsrap */
.btn {
  font-size: 14px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}

.btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.btn-primary:hover {
  background-color: #94c63d;
  border-color: #94c63d;
}

.btn-primary:active,
.btn-primary:focus  {
  background-color: #94c63d !important;
  border-color: #94c63d !important;
}

.btn-primary:disabled {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-primary:focus,
.form-control:focus {
  box-shadow: 0 0 0 .2rem rgba(176, 230, 84, 0.619) !important;
}

.form-control:focus {
  border-color: #94c63d !important;
}

.btn-secondary {
  background-color: #E7E6D0;
  border-color: #E7E6D0;
  color: #4D4B29;
}

.btn-secondary:hover {
  background-color: #cccbb8;
  border-color: #cccbb8;
}

.btn-success {
  background-color: #00e096;
  border-color: #00e096;
}
.btn-warning {
  background-color: #ffaa00;
  border-color: #ffaa00;
  color: #fff;
}
.btn-danger {
  background-color: #ff3d71;
  border-color: #ff3d71;
}
.btn-info {
  background-color: #0095ff;
  border-color: #0095ff;
}
.btn-outline-info {
  border-color: #0095ff;
}

.btn-outline-info:active,
.btn-outline-info:focus,
.btn-outline-info:hover {
  background-color: #0095ff !important;
  border-color: #0095ff !important;
}
.btn-outline-info a {
  color: #0095ff;
}
.btn-outline-info:active a,
.btn-outline-info:focus a,
.btn-outline-info:hover a {
  color: #fff;
}

.modal-backdrop {
  z-index: 2000 !important;
}

.modal {
  z-index: 2001 !important;
}

.progress-bar.bg-success {
  background-color: rgb(167, 225, 68) !important;
}

.progress-bar.bg-info {
  background-color: rgb(140, 140, 140) !important;
}

.cesium-viewer-bottom {
  bottom: 0;
  display: inline;
  left: 0;
  position: absolute;
}

.cesium-viewer-bottom .cesium-credit-textContainer {
  display: block !important;
}

.cesium-viewer-bottom a.cesium-credit-expand-link {
  font-size: .75rem;
  font-weight: 100;
  text-decoration: none;
  vertical-align: bottom;
}

.content {
  left: 10%;
  max-width: 30vw;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.copyright {
  bottom: 0;
  color: #fff;
  display: inline;
  font-size: .75rem;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* Overwrite Bootstrap form components */
.form-control {
  background-color: #F4F4E7 !important;
  border: 1px solid #DAD8BF;
}

.form-control::placeholder {
  color: #B3B08F;
  font-size: 15px;
}

.form-label {
  display: flex;
  justify-content: space-between;
  color:#8992A3;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.field-info {
  text-transform: none;
  color: #B3B08F;
  font-weight: 400;
}

/* Fade-out-fade-in animation for components */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.main-content {
  margin-left: 0;
  transition: margin .3s ease-out;
}

.main-content.expand-left {
  margin-left: var(--sidebar-width);
}

.main-content.expand-right {
  margin-right: var(--sidebar-width);
}

.main-content canvas {
  height: 100% !important;
  width: 100% !important;
}

.navbar-desktop {
  background: rgb(0, 0, 0);
  background: -webkit-linear-gradient(rgba(0, 10, 10, .6) 0%,  rgba(0, 0, 0, .1) 70%,  rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(rgba(0, 10, 10, .6) 0%,  rgba(0, 0, 0, .1) 70%,  rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(rgba(0, 10, 10, .6) 0%,  rgba(0, 0, 0, .1) 70%,  rgba(0, 0, 0, 0) 100%);
}

.navbar-dark .navbar-nav .nav-link {
  color: hsla(0, 0, 100%, 0.86);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.navbar-content-wrapper {
  justify-content: space-between;
}

.profile-picture {
  border-radius: 50%;
  height: 12rem;
  width: 12rem;
}

.sidebar-sidebar {
  width: var(--sidebar-width);
  z-index: 1800 !important;
}

/* Workaround for inputs not being clickable in sidebar */
.sidebar-content,
.sidebar-overlay {
  display: none;
}

/* Workaround for inputs not being clickable in sidebar */
/* .sidebar-root {
  position: unset !important;
} */

.sidebar-root.hidden {
  visibility: hidden;
}

/* Vertical card for sidebar content */
.vertical-card {
  flex-flow: row;
}

.vertical-card-left {
  display: flex;
}

.vertical-card-left-image {
  align-self: center;
  width: 70px;
  height: 70px;
  border-radius: 3px;
  background-size: cover;
  background-position: center center;
}

.project-user-image {
  border-radius: 100%;
}

.country-stats {
  width: 85%;
}

.country-stats > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.country-stats span:first-child {
  flex-basis: 60%;
  flex-grow: 0;
}

/* Overwrite some Bootstrap */
.dropdown-menu {
  padding: 0;
}

.dropdown-menu > .list-group-item {
  width: 22rem;
}
.dropdown-menu .list-group-item-logo {
  height: 2.25rem;
}
.dropdown-menu .list-group-item-icon {
  height: 2.25rem;
  width: 2.25rem;
}
.dropdown-menu .list-group-item-checkbox {
  align-self: center;
  color: #c6c2a3;
}
.dropdown-menu .list-group-item-checkbox.active {
  color: var(--color-primary);
}
.dropdown-menu .list-group-item-checkbox * {
  font-size: 1.5rem;
  width: 1.5rem;
}

.goal-filled {
  background: var(--color-primary);
  border: 1px var(--color-primary) solid;
  border-radius: var(--swipeable-button-height);
  color: #fff;
  height: var(--swipeable-button-height);
  line-height: var(--swipeable-button-height);
  position: relative;
  text-align: center;
  width: 100%;
}

.navbar .dropdown.nav-item {
  margin-right: 2rem;
}

.badge {
  background-color: #fff;
}

*.country,
*.country > .badge {
    background-color: #fff;
    color: #4D4B29;
}

*.medication,
*.medication > .badge {
    background-color: var(--color-medication);
    color: #fff;
}

*.money,
*.money > .badge {
    background-color: var(--color-money);
    color: #fff;
}

*.education,
*.education > .badge {
    background-color: var(--color-education);
    color: #fff;
}

*.food,
*.food > .badge {
    background-color: var(--color-food);
    color: #fff;
}

*.water,
*.water > .badge {
    background-color: var(--color-water);
    color: #fff;
}
*.reforestation,
*.reforestation > .badge {
    background-color: var(--color-reforestation);
    color: #fff;
}
*.hzpc,
*.hzpc > .badge {
    background-color: var(--color-hzpc);
    color: #fff;
}

.pick-location-info {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1;
  max-width: 300px;
}

.sr-card-element,
.sr-card-element * {
  background-color: #F4F4E7 !important;
  border: 1px solid #DAD8BF;
  color: #4D4B29 !important;
}

/* Overwrite some react-datetimerange-picker */
.react-datetimerange-picker__calendar-button,
.react-datetimerange-picker__clear-button {
  display: none;
}

@media only screen and (max-width: 768px) {
  body {
    font-size: calc(var(--body-font-size) * 0.75);
  }

  .btn-share {
    padding: .25rem .5rem;
  }

  .content {
    max-width: 60vw;
    right: calc(var(--spacing) * 2);
  }

  /* Overwrite some Bootsrap */
  .nav-link {
    padding: 0;
  }

  .nav-link-left {
    color: #fff;
    display: inline-flex;
    left: .25rem;
    padding: .375rem .5rem;
    position: absolute;
    top: .5rem;
    z-index: 1;
  }

  .nav-link-right {
    color: #fff;
    font-size: 18px;
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  .nav-link-right > .btn-share {
    font-size: 18px !important;
    width: 100%;
    height: 100%;
    line-height: 0;
  }

  .nav-link-right-search-text {
    color: #fff;
    padding-top: .125rem;
    position: absolute;
    right: 2.5rem;
    top: .5rem;
    z-index: 1;
  }

  .nav-link-right-container {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: .5rem;
    top: .5rem;
  }

  .nav-item-right-container {
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .nav-link-right-container .nav-link-right {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .nav-link-right-container > .nav-link-right {
      margin-bottom: .5rem;
  }

  .nav-item-right-container .nav-link-right {
    background-color: #e7e6d0;
    color: #aba87f;
  }
  .nav-item-right-container .nav-link-right:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .nav-item-right-container .nav-link-right:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .nav-item-right-container .nav-link-right.active {
    background-color: #c6c2a3;
    color: #4d4b29;
  }

  .sidebar-sidebar {
    width: 90vw;
  }
}

@media only screen and (min-width: 768px) {
  .btn-share-main {
    position: absolute;
    right: 1rem;
    top: 60px;
    z-index: 1;
  }
}
