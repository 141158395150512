.donation-options-selector {
    cursor: pointer;
}

.donation-options-selector .donation-options-item {
    margin-bottom: 1rem;
    position: relative;
}

.donation-options-item-checkbox {
    font-size: 25px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #FFF;
    z-index: 1;
}

/* @media only screen and (min-width: 768px) {
    .donation-options-item:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ccc;
        opacity: .2;
    }
    .donation-options-item:hover:after,
    .donation-options-item.selected:after {
        display: none;
    }
} */
