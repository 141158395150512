.infobox-wrapper {
    position: relative;
    z-index: 1050;
}

.infobox {
    --infobox-border-radius: 5px;
    z-index: 2;
    position: fixed;
    width: 90%;
    max-width: 320px;
    background-color: #fff;
    border-radius: var(--infobox-border-radius);
    transform: translate(-50%, -65px);
    -webkit-box-shadow: 0px 0px 19px 1px rgba(20, 20, 20, 1);
    -moz-box-shadow: 0px 0px 19px 1px rgba(20, 20, 20, 1);
    box-shadow: 0px 0px 19px 1px rgba(20, 20, 20, 1);

}

.infobox-arrow {
    bottom: -16px;
    position: absolute;
    left: 50%;
    background-color: #fff;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(0, 0, 0, 0.3); */
    transform: translate(-50%, -50%) rotate(45deg);
    height: 16px;
    width: 16px;
}

@media only screen and (max-width: 767px) {
    /* Fix mobile Safari not rendering infobox or rendering it invisible */
    .infobox-wrapper {
        position: unset;
        z-index: unset;
    }
}
