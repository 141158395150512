.navbar-desktop-item {
    background: #E7E6D0 !important;
    border-radius: 2px;
    margin-right: 1rem !important;
}

.navbar-desktop-item.show {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: #C6C3A2 !important;
    width: 360px;
}

.search-toggle {
    margin-right: 0 !important;
}

.navbar-desktop-item > a,
a.navbar-desktop-item,
.user-status {
    color: #4D4B29 !important;
    text-shadow: none !important;
    padding: .25rem .5rem;
}

.navbar-desktop-item.menu-trigger {
    align-items: center;
}

.navbar-content-wrapper > .navbar-nav {
    align-items: center;
}

.navbar-content-wrapper > .navbar-nav .dropdown-menu {
    border-radius: 0 !important;
    border: none !important;
}

.navbar-dark .navbar-nav .nav-link.search-active {
    background: #FFAA00 !important;
    color: #fff !important;
}

.main-dropdown > a {
    display: flex;
    align-items: center;
}

.main-dropdown > .dropdown-menu,
.main-dropdown > .dropdown-menu > .list-group-item {
    width: 100%;
}

.main-dropdown > a > div:first-child {
    flex-grow: 1;
}

/* .animatable-dropdown-wrapper {
    position: relative;
    width: 200px;
    margin-right: 1rem;
    transition: width .3s;
}

.animatable-dropdown-wrapper.open {
    width: 360px;
}

.animatable-dropdown-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 50;
} */

/*
@media only screen and (max-width: 1023px) {
    .navbar-content-wrapper > .navbar-nav {
        flex-direction: column !important;
        align-items: flex-end;
    }

    .main-dropdown {
        margin-right: 0 !important;
        margin-bottom: 1rem;
    }

    .share-btn {
        top: 200px;
    }
} */