.country-sidebar-stats-section {
    padding: 0.5em;
    margin-bottom: 0;
}
.country-sidebar-stats-section:nth-child(odd) {
    background: #eee;
}
.country-sidebar-stats-section:last-child {
    margin-bottom: 2rem;
}

.country-sidebar-stats-section span {
    font-size: .8rem;
}

.country-sidebar-stats-item {
    display: flex;
    justify-content: space-between;
    margin-right: 3rem;
}

.country-sidebar-wikipedia-extract {
    font-size: 14px;
    width: 90%;
    margin: 2rem 1rem;
    white-space: pre-line;
}

.country-sidebar-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.country-sidebar-wikipedia-heading {
    display: flex;
    align-items: center;
}

.country-sidebar-wikipedia-heading * {
    font-size: 20px;
    margin-right: 0 !important;
    text-align: right;
}
