.project-card {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
}

.project-card-cover {
    width: 100%;
    padding: .5rem;
    padding-top: 0.75rem;
    height: 120px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #979797;
}
.project-card-cover .badge + .badge {
    margin-left: .25rem;
}

.project-card-body {
    padding: .75rem .2rem;
}

.project-card-actions {
    margin-top: .5rem;
}
