.project-card {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    padding-bottom: 1.5rem;
}
.project-card:last-child {
    padding-bottom: 0; /* Do not add any padding bottom to the last project card */
}

.sidebar-search-input {
    width: 90% !important;
    margin: 0 auto;
}

.sidebar-search-input .input-group-append > button {
    background-color: #F4F4E7 !important;
    border: 1px solid #DAD8BF;
    border-left-width: 0;
    color: #B3B08F;
}

.sidebar-search-input .input-group-append > button:hover {
    background-color: rgb(225, 225, 212) !important;
    color: #959270;
}

.sidebar-search-input .input-group-append > button:focus {
    border-color: #94c63d !important;
    border-left-width: 1px !important;
    box-shadow: 0 0 0 .2rem rgba(176, 230, 84, 0.619) !important;
    background-color: rgb(225, 225, 212) !important;
    color: #959270;
}
