.attachment-info {
    background-color: rgb(57, 57, 57) !important;
    padding: 2rem;
    color: #fff !important;
}

.attachment-info h3,
.attachment-info p {
    color: #fff !important;
}

.attachment-panel {
    margin-bottom: 2rem;
    position: relative;
}

.attachment-panel-actions {
    display: flex;
    justify-content: space-between;
    margin-top: .5rem;
}

.attachment-panel-actions .btn {
    margin-right: .5rem;
}

.attachment-panel .carousel-item {
    height: 220px;
}

.attachment-panel .carousel-item.video {
    background-color: black !important;
}

.attachment-panel .carousel-item iframe {
    height: 100%;
    width: 100%;
}

.attachment-panel .carousel-control-next,
.attachment-panel .carousel-control-prev {
    align-items: flex-end !important;
    padding: .5rem;
}

.attachment-panel .carousel-control-next {
    justify-content: flex-end;
}


.attachment-panel .carousel-control-prev {
    justify-content: flex-start;
}

.attachment-item {
    background-position: center top;
    background-size: cover;
    /* display: flex;
    align-items: flex-start;
    justify-content: space-between; */
}

.attachment-badge {
    position: absolute;
    top: 8px;
    left: 8px;
}
