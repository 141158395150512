.payment-method-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.payment-method-container + .payment-method-container {
  margin-top: 5rem;
}
.payment-method-container .payment-method-wrapper {
  cursor: pointer;
  display: flex;
}
/* .rccs is a className defined in react-credit-cards */
.payment-method-container .payment-method-wrapper .rccs {
  margin: 0;
}
