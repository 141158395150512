.popup-button {
    margin-top: .5rem;
    width: 100% !important;
}

.popup-close {
    cursor: pointer;
    color: #fff;
}

.popup-header {
    border-top-left-radius: var(--infobox-border-radius);
    border-top-right-radius: var(--infobox-border-radius);
    background-color: #A7E144;
    width: 100%;
    padding: .5rem;
    padding-top: 0.75rem;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center 4px;
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.popup-header.placeholder {
    background-color: white;
    height: 50px;
    position: relative;
}

.popup-header.no-badge {
    justify-content: flex-end;
}

.popup-header.video {
    height: unset;
}

.popup-content {
    padding: 1.1rem;
}

.popup-content.video > .video-info {
    padding:.5rem 1.1rem;
}

.popup-content.video {
    padding: 0rem;
}

.popup-content-video-wrapper {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 56.25%;
}

.popup-content-video-wrapper > embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

