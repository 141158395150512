.sidebar-menu-nav {
    align-items: center;
    display: flex;
    flex-flow: column;
}

.sidebar-menu-nav > * {
    margin-bottom: 4rem;
}

.sidebar-menu-nav .tutorial-video {
    border-radius: .25rem;

    /* Set height to roughly match its target according to video's aspect ratio.
    For some reason all other tricks failed. */
    height: calc(var(--sidebar-width) / (16 / 9));

    margin-bottom: 1rem;
    width: 100%;
}

.sidebar-menu-auth {
    display: flex;
    margin-bottom: 3rem;
    width: 100%;
}
.sidebar-menu-auth a {
    flex-grow: 1;
}
.sidebar-menu-auth a:hover {
    text-decoration: none;
}
.sidebar-menu-auth a:first-child {
    margin-right: .5rem;
}
.sidebar-menu-auth a:last-child {
    margin-left: .5rem;
}

.login-form,
.signup-form {
    margin: 0 auto;
    max-width: 360px;
}

.agree-to-terms {
    margin: 1.5rem 0;
}
