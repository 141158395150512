/* Pasha styles – Brand-overrides, can split these out */
:root {
  --accent-color: #ed5f74;
  --success-color: #5fed82;
  --headline-color: var(--accent-color);
  --logo-image: url("https://storage.googleapis.com/stripe-sample-images/logo-pasha.svg");
}

.StripeElement {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  transition: box-shadow 150ms ease;
  -webkit-transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
