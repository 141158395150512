.thank-you-for-donation svg {
    animation: heartbeat 1s infinite;
    color: var(--color-primary);
}

/* Scale up & down faster in irregular intervals to get the throbbing effect */
@keyframes heartbeat {
    0% { transform: scale(1); }
    5% { transform: scale(1.1); }
    10% { transform: scale(1); }
    15% { transform: scale(1.2); }
    50% { transform: scale(1); }
    100% { transform: scale(1); }
}
