:root {
    --color-primary-darker: #456410;
}

.splash-screen {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}

.splash-screen .tutorial-video {
    flex: 1 1 auto;
    margin: 3rem 0 0 0;
    width: 90vw;
}

.splash-screen .splash-screen-button-close {
    box-shadow: 0 0 .5rem 0 var(--color-primary-darker);
}

.splash-screen.active {
    background: radial-gradient(circle, rgba(41,122,15, 0.8), rgb(167,225,68, 0.8));
}

.splash-screen-content {
    align-items: center;
    color: #fff !important;
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 60%;
}
.splash-screen-content * {
    color: #fff !important;
}

.splash-screen-action {
    align-items: center;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-around;
}

.splash-screen-action * {
    margin: .5rem 0 .75rem 0;
}

.cookie-notice {
    flex-grow: 0;
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    white-space: pre-wrap;
}

@media only screen and (max-width: 768px) {
    .cookie-notice {
        text-shadow: 0 0 .75rem var(--color-primary-darker);
    }

    .splash-screen-content {
        width: 90vw;
    }

    .splash-screen .tutorial-video {
        margin: .5rem 0 0 0;
    }
}

@media only screen and (max-width: 375px) {
    .splash-screen-content,
    .splash-screen .tutorial-video {
        width: 92vw;
    }
}

@media only screen and (max-width: 320px) {
    .splash-screen-content,
    .splash-screen .tutorial-video {
        width: 94vw;
    }
}
