.ReactSwipeButton {
  height: var(--swipeable-button-height);
  width: 100%;
}
.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rsbContainer {
  background: #fff;
  border: 1px var(--color-primary) solid;
  border-radius: var(--swipeable-button-height);
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.rsbcSlider {
  background: var(--color-primary);
  border-radius: calc(var(--swipeable-button-height) / 2);
  cursor: pointer;
  height: var(--swipeable-button-height);
  margin-left: -100%;
  padding-right: 6rem;
  position: absolute;
  text-align: right;
  top: 0;
  width: 100%;
  z-index: 100;
}

.rsbcSliderText {
  color: #fff;
  line-height: var(--swipeable-button-height);
  text-align: right;
}

.rsbcText {
  border-color: var(--color-primary);
  color: var(--color-primary);
  height: var(--swipeable-button-height);
  line-height: var(--swipeable-button-height);
  padding-right: 3rem;
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .rsbcSlider {
    padding-right: 3rem;
  }
  .rsbcText {
    padding-right: 2rem;
  }
}

/* :root {
  --color-primary: #a7e144;
  --swipeable-button-height: 3rem;
}

.ReactSwipeButton {
  float: left;
  height: var(--swipeable-button-height);
  position: relative;
  width: 100%;
}
.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rsbContainer {
  background: #d8d8d8;
  border: 1px var(--color-primary) solid;
  border-radius: var(--swipeable-button-height);
  float: left;
  height: 100%;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.rsbcSlider {
  background: var(--color-primary);
  border-radius: calc(var(--swipeable-button-height) / 2);
  cursor: pointer;
  height: var(--swipeable-button-height);
  left: 220px;
  margin-left: -100%;
  padding-right: 6rem;
  position: absolute;
  text-align: right;
  top: 0;
  width: 100%;
  z-index: 100;
}

.rsbcSliderText {
  color: #fff;
  line-height: var(--swipeable-button-height);
  text-align: right;
}

.rsbcText {
  border-color: var(--color-primary);
  color: var(--color-primary);
  height: var(--swipeable-button-height);
  left: 0;
  line-height: var(--swipeable-button-height);
  padding-right: 3rem;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
} */
